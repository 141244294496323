export enum Metrics {
  LEAD = "LEAD",
  OPPORTUNITY = "OPPORTUNITY",
  OPPORTUNITY_WON = "OPPORTUNITY_WON",
  APPROVED_CUSTOMER = "APPROVED_CUSTOMER",
}
export enum AddOns {
  IrisCard = "IRISCARD",
  IrisPay = "IRISPAY",
}
export enum AddOnsTitle {
  IrisCard = "ePrepaid",
  IrisPay = "IrisPay",
}