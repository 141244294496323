import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemSubmenu, Submenu } from 'src/lib/models/navigation.model';
import { MenuService } from 'src/lib/services/menu/menu.service';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit {
  @Input() titleNavitem: string;
  @Output() selected = new EventEmitter<string>();

  subMenu: Promise<Submenu[]>;

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    this.subMenu = this.menuService.getSubMenu(this.titleNavitem);
  }

  navigateSubmenu(route: ItemSubmenu) {
    if (this.verifyUrlExternal(route.href)) {
      this.openUrlExternal(route.href);
      return;
    }

    const w = window as any;
    w.routerSubject?.next(route.href);
    this.selected.emit('active');
  }

  verifyUrlExternal(url: string) {
    return url.startsWith('https://');
  }

  openUrlExternal(url: string): void {
    window.open(url, '_blank');
  }

  permissionSubmenu(items: ItemSubmenu[]): boolean {
    return items.some(item => item.hasAccess);
  }
}
