<div class="footer">
  <app-vigilado-img></app-vigilado-img>
  <span class="footer-text">{{ 'Footer.Copyright' | translate }}</span>
  <span *ngIf="userIp$ | async as userIp" class="footer-text"
    >{{ 'Footer.IP' | translate }}: {{ userIp }}</span
  >
  <a
    class="terms-conditions"
    [href]="termsAndConditionsURL"
    target="_blank"
    rel="noopener noreferrer"
  >
    {{ 'Footer.TermsAndConditions' | translate }}
  </a>
  <div class="social-network">
    <a
      *ngFor="let socialMedia of socialMediaList"
      [href]="socialMedia.url"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img [src]="socialMedia.imageUrl | asset" [alt]="socialMedia.name" />
    </a>
  </div>
</div>
