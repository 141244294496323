import { Injectable } from '@angular/core';
import { SessionService } from '@iris/iris-authentication';
import { NavItem, Submenu } from 'src/lib/models/navigation.model';
import { UserHasPermissionService } from '../common/user-has-permission.service';
import { RolePermissions } from './role.model';

@Injectable({ providedIn: 'root' })
export class MenuService {
  menus: NavItem[] = [
    {
      href: '/dashboard',
      title: 'Dashboard',
      icon: 'img/menu/ic-resume.svg',
      hasAccess: false,
      permissions: [RolePermissions.COMPLETE_SUMMARY],
    },
    {
      title: 'Transfers',
      icon: 'img/menu/ic-transferencias.svg',
      href: '/transfer',
      hasAccess: false,
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
        RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS,
        RolePermissions.IDENTIFICATION_ACCOUNTS,
        RolePermissions.PAYMENTS_PAYROLL_MANUAL,
        RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
        RolePermissions.PAYMENTS_PAYROLL_BATCHES,
        RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
        RolePermissions.OPERATION_DETAILS,
        RolePermissions.CARDS_MANAGEMENT,
      ],
      submenu: [
        {
          title: 'AccountManagement',
          items: [
            {
              title: 'AccountManagement.InscribeAccount',
              href: '/transfer/inscribed-accounts/inscribe-account',
              hasAccess: false,
              permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
            },
            {
              title: 'AccountManagement.IdentificationAccount',
              href: '/administration/tags/accounts',
              hasAccess: false,
              permissions: [RolePermissions.IDENTIFICATION_ACCOUNTS],
            },
          ],
        },
        {
          title: 'Transfers',
          items: [
            {
              title: 'Transfers.InscribedAccounts',
              href: '/transfer/inscribed-accounts',
              permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
              hasAccess: false,
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'Transfers.OwnIrisAccounts',
              href: '/transfer/own-iris',
              permissions: [RolePermissions.TRANSACTIONS_OWN_ACCOUNTS],
              hasAccess: false,
              image: 'img-cuentas-propias',
            },
            {
              title: 'Transfers.OtherIrisAccounts',
              href: '/transfer/other-iris',
              permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK],
              hasAccess: false,
              image: 'img-banco-iris',
            },
            {
              title: 'Transfers.OtherBankAccounts',
              href: '/transfer/other-bank',
              permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK],
              hasAccess: false,
              image: 'img-otros-bancos',
            },
            {
              title: 'Transfers.Batch',
              href: '/transfer/batch-transfer',
              permissions: [RolePermissions.TRANSACTIONS_BATCHES],
              hasAccess: false,
              hideOnMobile: true,
              image: 'img-en-lote',
            },
          ],
        },
        {
          title: 'Payments',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
            RolePermissions.PAYMENTS_PAYROLL_BATCHES,
            RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
          ],
          items: [
            {
              title: 'Payments.Manual',
              href: '/payment/manual',
              permissions: [
                RolePermissions.PAYMENTS_PAYROLL_MANUAL,
                RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
              ],
              hasAccess: false,
              image: 'img-pagos-manuales',
            },
            {
              title: 'Payments.Draft',
              href: '/payment/draft',
              permissions: [
                RolePermissions.PAYMENTS_PAYROLL_MANUAL,
                RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
              ],
              hasAccess: false,
              image: 'img-pagos-guardados',
            },
          ],
        },
        {
          title: 'TransactionStatus',
          items: [
            {
              title: 'TransactionStatus.OperationDetail',
              href: '/operations-history',
              hasAccess: false,
              permissions: [RolePermissions.OPERATION_DETAILS],
            },
            {
              title: 'TransactionStatus.Authorizations',
              href: '/pending-transactions/pending/requested',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
                RolePermissions.CARDS_MANAGEMENT,
              ],
              hasAccess: false,
              image: 'img-autorizacion',
            },
            {
              title: 'TransactionStatus.ScheduledTransactions',
              href: '/pending-transactions/scheduled/transactions',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
              ],
              hasAccess: false,
              image: 'img-programadas',
            },
            {
              title: 'TransactionStatus.HistoryTransactions',
              hasAccess: false,
              href: '/transactions-history/transactions',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
              ],
            },
          ],
        },
      ],
    },
    {
      title: 'ePrepaid',
      icon: 'img/menu/ic-eprepaid.svg',
      href: '/iriscard',
      hasAccess: true,
      highlight: true,
    },
    {
      title: 'IrisPay',
      icon: 'img/menu/ic-recaudos.svg',
      href: '/irispay',
      hasAccess: true,
      highlight: true,
    },
    {
      title: 'Company',
      icon: 'img/menu/ic-administracion.svg',
      href: '/administration',
      hasAccess: true,
      permissions: [
        RolePermissions.AREAS_AND_TEAMS,
        RolePermissions.ROLE_AND_LIMITS,
        RolePermissions.USERS,
        RolePermissions.USER_LOGS,
        RolePermissions.PLANS,
        RolePermissions.IDENTIFICATION_TAGS
      ],
      submenu: [
        {
          title: 'AccountIris',
          items: [
            {
              title: 'AccountIris.Plan',
              href: '/administration/plans/account-selector',
              hasAccess: false,
              permissions: [RolePermissions.PLANS]
            },
          ],
        },
        {
          title: 'RolesUser',
          items: [
            {
              title: 'RolesUser.AreasTeams',
              href: '/user-management/areas',
              hasAccess: false,
              permissions: [RolePermissions.AREAS_AND_TEAMS]
            },
            {
              title: 'RolesUser.RolesLimits',
              href: '/user-management/roles',
              hasAccess: false,
              permissions: [RolePermissions.ROLE_AND_LIMITS]
            },
            {
              title: 'RolesUser.UserProfiles',
              href: '/user-management/users',
              hasAccess: false,
              permissions: [RolePermissions.USERS]
            },
            {
              title: 'RolesUser.UsersLog',
              href: '/user-management/logs',
              hasAccess: false,
              permissions: [RolePermissions.USER_LOGS]
            },
          ],
        },
        {
          title: 'Setting',
          items: [
            {
              title: 'Setting.IdentificationTags',
              href: '/administration/tags/transactions/deposit-accounts',
              hasAccess: false,
              permissions: [RolePermissions.IDENTIFICATION_TAGS]
            },
          ],
        },
        {
          title: 'Iris',
          items: [
            {
              title: 'Iris.Contact',
              href: 'https://www.iris.com.co/home-centro-de-ayuda/',
              hasAccess: true
            },
            {
              title: 'Iris.Regulation',
              href: 'https://www.iris.com.co/centro-legal/reglament-cuenta-de-ahorros-digital/',
              hasAccess: true
            },
          ],
        },
      ],
    },
    {
      title: 'Profile',
      icon: 'img/menu/ic-gesion-de-usuario.svg',
      href: '/user-management',
      hasAccess: false,
      permissions: [
        RolePermissions.NOTIFICATIONS,
        RolePermissions.USERS
      ],
      submenu: [
        {
          title: 'Configuration',
          items: [
            {
              title: 'Configuration.Notifications',
              href: '/administration/notifications',
              hasAccess: false,
              permissions: [RolePermissions.NOTIFICATIONS],
            }
          ],
        },
        {
          title: 'User',
          items: [
            {
              title: 'User.UserDetail',
              href: '/user-management/users',
              permissions: [RolePermissions.USERS],
              hasAccess: false
            }
          ],
        },
        {
          title: 'Security',
          items: [
            {
              title: 'Security.ChangePassword',
              href: '/administration/change-password',
              permissions: [RolePermissions.USERS],
              hasAccess: false
            }
          ],
        }
      ],
    }
  ];

  complementsSetFocus = new Map<string, string>([
    ['/complements/irispay', '/irispay'],
  ]);

  constructor(
    private hasPermissionService: UserHasPermissionService,
    private authService: SessionService,
  ) {
    this.handleUserPermissions();
  }

  handleUserPermissions() {
    this.menus.map(item => {
      if (item.permissions?.length > 0) {
        item.hasAccess = this.hasPermissionService.hasPermission(
          item.permissions
        );
      }
      item.submenu?.forEach(async submenu => {
        submenu.items.forEach(item =>{
          if (item.permissions?.length > 0) {
            item.hasAccess = this.hasPermissionService.hasPermission(item.permissions);
          }
        });
      });
    });
  }

  async getMenus(): Promise<NavItem[]> {
    await this.authService.refreshToken();
    this.handleUserPermissions();
    return this.menus;
  }

  async getSubMenu(menuTitle: string): Promise<Submenu[]> {
    this.handleUserPermissions();
    return this.menus.find(menu => menu.title === menuTitle).submenu;
  }
}
