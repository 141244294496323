import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import es from '@angular/common/locales/es';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import {
  assetUrl,
  IrisApplication,
  IrisBaseConfiguration,
  IrisBaseConstants,
  IrisBaseModule,
} from '@iris/iris-base';
import {
  IrisAuthenticationConstants,
  IrisAuthenticationConfiguration,
  TokenInterceptor,
  ResponseInterceptor,
} from '@iris/iris-authentication';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { PrivateLayoutComponent } from './components/private-layout/private-layout.component';
import { LogoutConfirmationComponent } from './components/private-layout/logout-confirmation/logout-confirmation.component';
import { MenuComponent } from './components/private-layout/menu/menu.component';
import { MobileMenuComponent } from './components/private-layout/menu/mobile-menu/mobile-menu.component';
import { HandleNoInternetService } from 'src/lib/services/network/handleNoInternet.service';
import { environment } from '../environments/environment';
import { HandleSessionControlService } from 'src/lib/services/session/handleSessionControl.service';
import { FooterModule } from './components/footer/footer.module';
import { SubmenuComponent } from './components/private-layout/menu/submenu/submenu.component';

// Add colombia to Angular format
registerLocaleData(es);

function buildTranslationResources() {
  const modules = [
    'footer',
    'navbar'
  ];

  const resouces = [
    { prefix: assetUrl('/i18n/base.'), suffix: '.json' },
  ];

  modules.forEach(module => {
    resouces.push({
      prefix: assetUrl(`/i18n/modules/${module}.`),
      suffix: '.json',
    });
  });

  return resouces;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, buildTranslationResources());
}

export function IrisBaseConfigurationFactory(
  handler: HandleNoInternetService,
): IrisBaseConfiguration {
  return {
    application: IrisApplication.BANCA_DIGITAL,
    handleNetworkIssues: handler,
  } as IrisBaseConfiguration;
}

export function IrisAuthenticationConfigurationFactory(
  handler: HandleSessionControlService,
): IrisAuthenticationConfiguration {
  return {
    accountApi: environment.accountApi,
    websocketApi: environment.websocketApi,
    holidaysApi: environment.holidaysApi,
    loginApi: environment.loginApi,
    staticApiKey: environment.staticApiKey,
    sessionConfiguration: environment.sessionConfiguration,
    handleSessionControlService: handler,
  } as IrisAuthenticationConfiguration;
}

@NgModule({
  declarations: [
    AppComponent,
    PrivateLayoutComponent,
    LogoutConfirmationComponent,
    MenuComponent,
    MobileMenuComponent,
    SubmenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    CommonModule,
    NzDatePickerModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IrisBaseModule,
    FooterModule
  ],
  exports: [NzDatePickerModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '' },
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'COP' },
    {
      provide: IrisBaseConstants.CONFIGURATION,
      useFactory: IrisBaseConfigurationFactory,
      deps: [HandleNoInternetService],
    },
    {
      provide: IrisAuthenticationConstants.CONFIGURATION,
      useFactory: IrisAuthenticationConfigurationFactory,
      deps: [HandleSessionControlService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}