import { Component, OnInit } from '@angular/core';
import { AccountService } from '@iris/iris-authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  socialMediaList = [
    {
      name: 'linkedin',
      imageUrl: 'icons/illustrated-icons/social-media/linkedin.svg',
      url: 'https://co.linkedin.com/company/irisfinanciera',
    },
    {
      name: 'facebook',
      imageUrl: 'icons/illustrated-icons/social-media/facebook.svg',
      url: 'https://www.facebook.com/IRISFinanciera',
    },
    {
      name: 'youtube',
      imageUrl: 'icons/illustrated-icons/social-media/youtube.svg',
      url: 'https://www.youtube.com/channel/UCad8BhK3VW5NFUhjSpXJmKw',
    },
  ];

  userIp$: Observable<string> = new Observable();
  termsAndConditionsURL = environment.termsAndConditionsURL

  constructor(private accountService: AccountService) {}

  ngOnInit() {
    this.userIp$ = this.accountService.getUserIp();
  }

}
