export const environment = {
  production: true,
  idleTimeout: 5,
  accountApi: 'https://api.dev.irsbnk.co/empresas-1/account',
  groupApi: 'https://api.dev.irsbnk.co/empresas-financial-1/groups',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas-1/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas-1/login',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket-1',
  staticApiKey: 'gA3QHPRfjJqYCJ6ymvUG5AuwzUxKAanaY5AOEcW1',
  sessionConfiguration: {
    domain: 'empresas-1.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  termsAndConditionsURL: 'https://www.iris.com.co/centro-legal/terminos-y-condiciones',
  metricsApi:'https://api.dev.irsbnk.co/empresas-complements/metrics',
  addOnsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
};
