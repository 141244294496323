import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NavItem } from 'src/lib/models/navigation.model';
import { SubMenuPermissions } from 'src/lib/services/menu/menu.model';
import { MenuService } from 'src/lib/services/menu/menu.service';
import { MetricsService } from 'src/lib/services/metrics/metrics.service';

declare var window: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MenuComponent implements OnInit {
  navItems: NavItem[];

  isActiveSubmenu = [
    SubMenuPermissions.TRANSFERS,
    SubMenuPermissions.MYCOMPANY,
    SubMenuPermissions.MYPROFILE
  ]

  isSubmenuSelected = true;

  constructor(
    private menuService: MenuService,
    private changeDetector: ChangeDetectorRef,
    private metricsService: MetricsService,
  ) {
    window.refreshNavbar = () => {
      this.ngOnInit();
    };
  }

  ngOnInit() {
    this.menuService.getMenus().then(m => {
      m.forEach(m => {
        m.active = false;
      });

      let route = window.location.pathname;
      if (route === '/') route = '/dashboard';

      const basePath = this.getResetFocusRoute(route) ?? route;
      const navItem = m.find(k => basePath.startsWith(k.href));
      if (navItem) navItem.active = true;

      this.navItems = m;
      this.changeDetector.detectChanges();
    });
  }
  
  navigate(route: NavItem) {
    const w = window as any;
    w.routerSubject?.next(route.href);
    
    this.navItems.forEach(m => {
      m.active = false;
    });

    route.active = true;
    this.metricsService.registrerMetrics(route?.title);
    this.changeDetector.detectChanges();
  }

  private getResetFocusRoute(route: string): string | undefined {
    return this.menuService.complementsSetFocus.get(route);
  }

  activeMenu(title: SubMenuPermissions) :boolean{
    return this.isActiveSubmenu.includes(title) && this.isSubmenuSelected;
  }

  enterMenu(){
    this.isSubmenuSelected = true;
  }

  selectedSubmenu(route: NavItem){
    this.isSubmenuSelected = false;
    
    this.navItems.forEach(m => {
      m.active = false;
    });

    route.active = true;
  }
}